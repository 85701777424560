// @ts-ignore
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { head, toUpper, pipe, split, take, join, map, filter, isNil } from 'ramda';
import { makePresenter } from 'utils/PropTypesPresenter';
import defaultOrganization from 'utils/DefaultOrganization';
import appRoutes from 'appRoutes';
import CustomStatusPresenter from 'presenters/CustomStatusPresenter';
import SubscriptionPresenter from './SubscriptionPresenter';

export const ACTABLE_TYPES = {
  school: 'School',
  schoolDistrict: 'SchoolDistrict',
  group: 'Group',
  college: 'College',
  company: 'Company',
  stateEducationAgency: 'StateEducationAgency',
};

export const SHARING_SETTINGS_WITH_COMPANY = [
  {
    title: 'Feedback',
    description: 'Product Feedback',
    shareDataField: 'shareProductFeedbackWithCompany',
  },
  {
    title: 'Usage',
    description: 'Product Usage Data',
    shareDataField: 'shareProductUsageWithCompany',
  },
  {
    title: 'Pricing',
    description: 'Product Pricing Data',
    shareDataField: 'shareProductPricingWithCompany',
  },
  {
    title: 'IMPACT',
    description: 'Product IMPACT Data',
    shareDataField: 'shareImpactWithCompany',
    dependsOn: ['shareProductFeedbackWithCompany', 'shareProductPricingWithCompany'],
  },
];

export const SHARING_SETTINGS_WITH_EDUCATORS = [
  {
    title: 'Inventory Data',
    description: 'Inventory data',
    shareDataField: 'shareProductInventoryWithEducators',
  },
  {
    title: 'Feedback',
    description: 'Product Feedback Data',
    shareDataField: 'shareProductFeedbackWithEducators',
    dependsOn: 'shareProductInventoryWithEducators',
    anonymouslyShareField: 'shareProductFeedbackWithEducatorsAnonymously',
  },
  {
    title: 'Pricing',
    description: 'Product Pricing Data',
    shareDataField: 'shareProductPricingWithEducators',
    anonymouslyShareField: 'shareProductPricingWithEducatorsAnonymously',
    dependsOn: 'shareProductInventoryWithEducators',
  },
  {
    title: 'IMPACT',
    description: 'Product IMPACT Data',
    shareDataField: 'shareImpactWithEducators',
    dependsOn: ['shareProductFeedbackWithEducators', 'shareProductPricingWithEducators'],
    anonymouslyShareField: 'shareImpactWithEducatorsAnonymously',
  },
  {
    title: 'LearnCommunity',
    description: 'LearnCommunity',
    blockField: 'blockLearnCommunityForEducators',
    dependsOn: 'shareProductInventoryWithEducators',
  },
];

export const REQUEST_PRODUCT_FOR = [
  {
    value: 'all_members',
    label: 'All members',
  },
  {
    value: 'members',
    label: 'All direct members, including educators',
  },
  {
    value: 'administrators',
    label: 'Only administrators',
  },
  {
    value: 'nobody',
    label: 'No one',
  },
];

const shape = {
  id: PropTypes.number.isRequired,
  actableType: PropTypes.string,
  actableId: PropTypes.number,
  headerBackgroundColor: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customStatuses: PropTypes.array.isRequired,
  customPrivacyStatuses: PropTypes.array.isRequired,
  organizationUrl: PropTypes.string,
  shareImpactWithEducators: PropTypes.bool,
  shareProductPricingWithEducators: PropTypes.bool,
  shareProductFeedbackWithEducators: PropTypes.bool,
  header: PropTypes.string,
  headerTextColor: PropTypes.string,
  headerLogo: PropTypes.string,
  loginLogo: PropTypes.string,
  loginLogoUrl: PropTypes.string,
  logo: PropTypes.string,
  showHeader: PropTypes.bool,
  studentCount: PropTypes.number,
  educatorCount: PropTypes.number,
  schoolCount: PropTypes.number,
  toolCount: PropTypes.number,
  userCount: PropTypes.number,
  isAnonymous: PropTypes.bool,
  subscription: SubscriptionPresenter.shape(),
  chromeExtensionConfigured: PropTypes.bool,
  showPublicProfile: PropTypes.bool,
  showApprovedApps: PropTypes.bool,
  allowPublicProfileEmbedding: PropTypes.bool,
  allowToRequestProductsFor: PropTypes.string,
  autoApproveMembershipRequests: PropTypes.bool,
  subdomain: PropTypes.string,
  chromeExtensionSkin: PropTypes.string,
  sharingUsage: PropTypes.bool,
  state: PropTypes.string,
  city: PropTypes.string,
  singleSignOn: PropTypes.object,
  domains: PropTypes.arrayOf(PropTypes.object),
  county: PropTypes.object,
  updatedAt: PropTypes.string,
  isAllowedToGrade: PropTypes.bool,
  useOrgNameForNotifications: PropTypes.bool,
  blockLearnCommunityForEducators: PropTypes.bool,
  canvas_setting: PropTypes.object,
};

export type Organization = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  isCompany(organization: Organization) {
    return organization.actableType === ACTABLE_TYPES.company;
  },

  initials(organization: Organization) {
    const takeTwoCapitalLetters = pipe(split(' '), take(2), map(head), join(''), toUpper);
    return takeTwoCapitalLetters(organization.name);
  },

  libraryName(organization: Organization) {
    // @ts-ignore
    const baseName = this.isCompany(organization) ? this.name(defaultOrganization) : this.name(organization);
    return `${baseName} Library`;
  },

  publicLibrayName(organization: Organization) {
    return `${this.name(organization)}'s Public Library`;
  },

  publicWebsiteName(organization: Organization) {
    return `${this.name(organization)}'s Website`;
  },

  libraryPath(organization: Organization) {
    // @ts-ignore
    return organization.id && !this.isCompany(organization) ? appRoutes.organizationToolsPath() : appRoutes.toolsPath();
  },

  impactAvailableForEducators(organization: Organization) {
    return (
      this.shareProductPricingWithEducators(organization) &&
      this.shareProductFeedbackWithEducators(organization) &&
      this.shareImpactWithEducators(organization)
    );
  },

  feedbackAvailableForEducators(organization: Organization) {
    return this.shareProductFeedbackWithEducators(organization);
  },

  pricingAvailableForEducators(organization: Organization) {
    return this.shareProductPricingWithEducators(organization);
  },

  hasFeature(organization: Organization, feature: string) {
    const subscription = this.subscription(organization);
    return SubscriptionPresenter.hasFeature(subscription, feature);
  },

  hasSubscription(organization: Organization) {
    const subscription = this.subscription(organization);
    return !isNil(subscription);
  },

  isChromeExtensionConfigured(organization: Organization) {
    return this.chromeExtensionConfigured(organization);
  },

  activeCustomStatuses(organization: Organization) {
    const customStatuses = this.customStatuses(organization);
    return customStatuses.filter(status => CustomStatusPresenter.isActive(status));
  },

  approvedActiveCustomStatuses(organization: Organization) {
    const customStatuses = this.customStatuses(organization);
    return customStatuses.filter(
      status => CustomStatusPresenter.isActive(status) && CustomStatusPresenter.systemStatus(status) === 'approved',
    );
  },

  activeCustomPrivacyStatuses(organization: Organization) {
    const customPrivacyStatuses = this.customPrivacyStatuses(organization);
    return customPrivacyStatuses.filter(status => CustomStatusPresenter.isActive(status));
  },

  pplActiveCustomStatuses(organization: Organization) {
    const customStatuses = this.customStatuses(organization);
    return customStatuses.filter(
      status => CustomStatusPresenter.isActive(status) && CustomStatusPresenter.showInPublicProfile(status),
    );
  },

  pplActiveCustomPrivacyStatuses(organization: Organization) {
    const customPrivacyStatuses = this.customPrivacyStatuses(organization);
    return customPrivacyStatuses.filter(
      status => CustomStatusPresenter.isActive(status) && CustomStatusPresenter.showInPublicProfile(status),
    );
  },

  publicProfileUrl(organization: Organization) {
    return `${this.organizationUrl(organization)}/new/public`;
  },

  editPublicProfileUrl(organization: Organization) {
    return `${gon.appHost}/new/organizations/${organization.id}/public`;
  },

  baseOrganizationPath(organization: Organization) {
    return `/new/organizations/${organization.id}`;
  },

  isLearnCommunity(organization: Organization) {
    return this.name(organization) === 'LearnCommunity';
  },
  pluralizedCount(count) {
    return ` ${count} ${pluralize('organization', count)}`;
  },

  sharedOrganizations(organizations: Array<Organization>) {
    return filter(o => Boolean(this.sharingUsage(o)), organizations);
  },

  organizationStateCity(organization: Organization) {
    return [this.city(organization), this.state(organization)].filter(Boolean).join('/');
  },

  countyName(organization: Organization): string {
    if (isNil(this.county(organization))) {
      return '';
    }

    return (this.county(organization) as any)?.name ?? '';
  },

  stateName(organization: Organization): string {
    return (this.county(organization) as any)?.state?.name ?? '';
  },

  isCollege(organization: Organization): boolean {
    return organization.actableType === ACTABLE_TYPES.college;
  },
});
